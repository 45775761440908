import React, { useEffect } from "react";
import PageWithCmsWrapper from "../components/shared/page-with-cms-wrapper";
import { graphql } from "gatsby";
import cx from "classnames";
import CustomButton from "../components/shared/custom-button";
import { STATUS, validateEmail } from "../constants/contact";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, FormControl, MenuItem, Select, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { SelectArrow } from "../pages/contact-us";
import { updateSnakeCaseToCapitalisedText } from "../utils/helpers";
import InputMask from "react-input-mask";
import { navigate } from "gatsby";
import Typography from "@material-ui/core/Typography";
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: 50,
  },
  passwordFormWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  submitButton: {
    padding: "8px 38px",
    margin: 0,
    fontSize: "1.2em",
    lineHeight: 1.5,
  },
  fieldsContainerMini: {
    width: "48%",
    marginBottom: 30,

    [theme.breakpoints.down(680)]: {
      width: "100%",
      marginBottom: 30,
    },
  },
  fieldsContainer: {
    maxWidth: 450,
    width: "100%",
    marginBottom: 30,
  },
  label: {
    width: "100%",
    display: "block",
    fontSize: ".75rem",
    marginBottom: 10,
    color: "#5D5D5D",
    textTransform: "uppercase",
  },
  formControl: {
    width: "100%",
    "&.MuiOutlinedInput-notchedOutline": {
      borderColor: "green",
    },
  },
  input: {
    outline: 0,
    borderRadius: 8,
    border: "1px solid #ddd",
    transition: "border .12s ease-in-out",
    padding: 10,
    fontSize: "1.1rem",
    lineHeight: "2rem",
    width: "100%",
    fontFamily: "\"Roboto\", sans-serif",
    paddingLeft: 18,

    "&:focus": {
      outline: 0,
      border: "1px solid rgba(241, 90, 41, 1) !important",
    },
    "&:active": {
      outline: 0,
      border: "1px solid rgba(241, 90, 41, 1)",
    },
  },
  inputWithError: {
    borderColor: "red !important",
  },
  blurStyle: {
    filter: "blur(1px)",
  },
  formControlInput: {
    padding: "12px 14px",
  },
  formControlInputOutlined: {
    padding: 10,
    paddingLeft: 18,
    fontSize: "1.1rem",
    lineHeight: "2rem",
  },
  inputError: {
    color: "#cc3340",
    borderRadius: 4,
    lineHeight: ".8rem",
    marginTop: 5,
    padding: 5,
    position: "relative",
    textAlign: "left",
    verticalAlign: "middle",
    fontSize: ".75rem",
    alignItems: "center",
    display: "flex",
  },
  errorImg: {
    margin: "-2px 5px 0 0",
  },
  formContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    marginTop: 45,
    position: "relative",
  },
  fakeBlockWithScrollingKey: {
    position: "absolute",
    top: "-100px",
  },
  formForBot: {
    maxHeight: 0,
    overflow: "hidden",
  },
}));

const initialSnackbarData = {
  status: "",
  text: "",
  open: false,
};

const LandingPagesTemplate = ({
                                location,
                                path,
                                data: {
                                  strapiLandingPages: {
                                    content,
                                    SeoTitle,
                                    MetaDescriptions,
                                    image,
                                    formFields: {
                                      fields,
                                      hidden_fields,
                                    },
                                    submitButtonText,
                                    addPagePathToRequestBody,
                                    requestUrl,
                                    runIfRequestIsSuccessOPTION,
                                    runIfRequestIsSuccessBODY,
                                    password,
                                  },
                                },
                              }) => {
  const classes = useStyles();
  const [form, setForm] = React.useState({});
  const [hiddenFields, setHiddenFields] = React.useState({});
  const [errorsList, setErrorsList] = React.useState({});
  const [isRequestSend, setIsRequestSend] = React.useState(false);
  const [botValue, setBotValue] = React.useState("");
  const [snackbarState, setSnackbarState] = React.useState(initialSnackbarData);
  const [hasAccess, setHasAccess] = React.useState(false);
  const [userPassword, setUserPassword] = React.useState("");
  const [userPasswordError, setUserPasswordError] = React.useState(false);
  const [renderHubspotForm, setRenderHubspotForm] = React.useState(true);

  useEffect(() => {
    // set default values
    const _form = {};
    fields.forEach(field => _form[field.api_name] = field.value || "");
    setForm(_form);

    // set empty error list
    const _errors = {};
    fields.forEach(field => _errors[field.api_name] = "");
    setErrorsList(_errors);

    // set empty error list
    const _hiddenFields = {};
    hidden_fields.forEach(field => _hiddenFields[field.api_name] = field.value);
    setHiddenFields(_hiddenFields);

    if (!password) {
      setHasAccess(true);
      addHubspotForm();
    }
  }, []);

  const trackByGA = () => {
    typeof window !== "undefined" && window.gtag && window.gtag("event", "click", {
      category: "Form Submission",
      label: "page slug",
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarState(initialSnackbarData);
  };

  const addHubspotForm = async () => {
    /* eslint-disable */
    const queryParamsFromUrl = queryString.parse(location.search);
    const _hubspotFormId = (queryParamsFromUrl["hubspot-form-id"]);

    if (_hubspotFormId) {
      const script = document.createElement("script");
      script.src = "https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);
      script.addEventListener("load", async () => {
        if (window.hbspt) {
          const formObj = {
            region: 'na1',
            portalId: '39841862',
            formId: _hubspotFormId,
            target: "#hubspotForm",
            onFormReady: function() {
              setRenderHubspotForm(true);
              document.getElementById("loader").remove();
              const nameField = document.getElementsByTagName("firstname")[0];
              nameField.focus();
              window && window.scrollBy(0, nameField.getBoundingClientRect().top - 200);
              let { message, reason, subject } = parsedQuery;
              if (message) {
                document.getElementsByTagName("message")[0].value = message;
              }
              if (reason) {
                document.getElementsByTagName("reason")[0].value = reason;
              }
              if (subject) {
                document.getElementsByTagName("subject")[0].value = subject;
              }
            },
          };
          window.hbspt.forms.create(formObj);

          await awaitDelay(300);
          if (document.getElementById("loader")) {
            setRenderHubspotForm(false);
          }
        }
      });
    } else {
      setRenderHubspotForm(false);
    }
  };

  function awaitDelay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const checkAccess = () => {
    if (userPassword === password) {
      setHasAccess(true);
      setUserPasswordError(false);
      addHubspotForm();

      trackByGA();
    } else {
      setUserPasswordError(true);
    }
  };

  const changeUserPassword = (event) => {
    if (userPasswordError) {
      setUserPasswordError(false);
    }
    setUserPassword(event.currentTarget.value);
  };

  const handleEnter = (event) => {
    if (userPasswordError) {
      setUserPasswordError(false);
    }

    if (
      event.location.toString() === "3" ||
      event.keyCode.toString() === "13" ||
      event.code === "Enter" ||
      event.code === "NumpadEnter"
    ) {
      checkAccess();
      event.preventDefault();
    }
  };

  const changeVal = (event, _name) => {
    const { name, value } = event.target;
    if (errorsList[name]) {
      setErrorsList({ ...errorsList, [name ?? _name]: "" });
    }
    const newFormVal = { ...form, [name ?? _name]: value };
    setForm(newFormVal);
  };

  const sentRequest = async () => {
    setIsRequestSend(true);
    const optionalParms = addPagePathToRequestBody ? { "landingPage": `https://dev.slatepages.com${path}` } : {};

    try {
      let result = await fetch(requestUrl, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify({
          ...hiddenFields,
          ...optionalParms,
          ...form,
        }),
      });

      if (result.ok) {
        switch (runIfRequestIsSuccessOPTION) {
          case "redirect":
            if (window) {
              runIfRequestIsSuccessBODY.startsWith("/")
                ? await navigate(runIfRequestIsSuccessBODY)
                : window.location.href = runIfRequestIsSuccessBODY;
            }
            break;
          case "showNotification":
            setSnackbarState({
              status: STATUS.success,
              text: runIfRequestIsSuccessBODY,
              open: true,
            });
            break;
          case "none":
            break;
          default:
            break;
        }
      } else {
        let response = await result.json();
        if (response.validationErrors && response.validationErrors.dataErrors) {
          const newErrors = { ...errorsList };
          Object.keys(response.validationErrors.dataErrors).forEach((key => {
            if (key in form) {
              newErrors[key] = response.validationErrors.dataErrors[key];
            }
          }));
          setErrorsList(newErrors);
        } else {
          setSnackbarState({
            status: STATUS.error,
            text: "Something seems to have gone wrong. Try again.",
            open: true,
          });
        }
      }
    } catch {
      setSnackbarState({
        status: STATUS.error,
        text: "Something seems to have gone wrong. Try again.",
        open: true,
      });
    }
    setIsRequestSend(false);
  };

  const formSubmit = () => {
    if (botValue !== "") {
      return;
    }

    let newErrorsList = { ...errorsList };

    Object.keys(errorsList).map((key, index) => {
      let value = form[key];

      if ((!value || value.replaceAll(" ", "").length === 0) && fields[index].required) {
        newErrorsList[key] = "The value is required";
      } else if (key === "email" && value && !validateEmail(value)) {
        newErrorsList[key] = `Email: ${value} is not properly formatted`;
      } else {
        newErrorsList[key] && (newErrorsList[key] = ``);
      }
    });
    setErrorsList(newErrorsList);
    let hasErrors = false;
    Object.keys(errorsList).forEach((key) => {
      if (!!newErrorsList[key]) {
        hasErrors = true;
      }
    });

    if (!hasErrors) {
      trackByGA();
      sentRequest().then(() => {
      });
    }
  };

  const fakeEvent = () => {
    setBotValue("bot");
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbarState.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={snackbarState.status}>
          {snackbarState.text}
        </Alert>
      </Snackbar>
      <PageWithCmsWrapper
        path={path}
        content={hasAccess ? content : ""}
        image={hasAccess ? image : ""}
        useSpecialLandingPageStyle
        seoProps={{ title: SeoTitle, description: MetaDescriptions, image: image, noIndexed: true }}
      >
        {
          hasAccess ?
            <div className={classes.formContainer}>

              <div id={"customForm"} className={classes.fakeBlockWithScrollingKey} />
              {/*// for bot*/}
              <form onSubmit={fakeEvent} className={classes.formForBot}>
                <label>
                  Name:
                  <input type="text" id={"_name"} required onChange={setBotValue} />
                </label>
                <label>
                  Email:
                  <input type="text" id={"_email"} required onChange={setBotValue} />
                </label>
                <label>
                  Phone:
                  <input type="text" id={"_phone"} required onChange={setBotValue} />
                </label>
                <input type="submit" value="Submit" onClick={fakeEvent} />
              </form>


              {!renderHubspotForm && JSON.stringify(form) !== JSON.stringify({}) && <>
                {fields.map((field) => {
                  const _placeholder = field.placeholder ?? updateSnakeCaseToCapitalisedText(field.api_name);
                  const _title = field.title ?? _placeholder;

                  if (field.type === "selector") {
                    return <Selector
                      key={field}
                      isRequestSend={isRequestSend}
                      field={field}
                      placeholder={_placeholder}
                      title={_title}
                      errorsList={errorsList}
                      form={form}
                      changeVal={changeVal}
                    />;
                  } else if (field.type === "phone") {
                    return <PhoneInput
                      key={field}
                      isRequestSend={isRequestSend}
                      field={field}
                      placeholder={_placeholder}
                      title={_title}
                      errorsList={errorsList}
                      form={form}
                      changeVal={changeVal}
                    />;
                  } else {
                    return <TextInput
                      key={field}
                      isRequestSend={isRequestSend}
                      field={field}
                      title={_title}
                      placeholder={_placeholder}
                      errorsList={errorsList}
                      form={form}
                      changeVal={changeVal}
                    />;
                  }
                })}
                <div className={classes.formForBot}>
                  <CustomButton variant="contained" color="primary" mb={3}
                                onClick={fakeEvent}
                                className={classes.formForBot}
                                disabled={isRequestSend}
                  >
                    Bot Submit
                  </CustomButton>
                  <CustomButton variant="contained" color="primary" mb={3}
                                onClick={fakeEvent}
                                className={classes.formForBot}
                                disabled={isRequestSend}
                  >
                    Bot Submit
                  </CustomButton>
                </div>


                <CustomButton variant="contained" color="primary" mb={3}
                              onClick={formSubmit}
                              className={classes.submitButton}
                              disabled={isRequestSend}
                >
                  {submitButtonText || "Submit"}
                </CustomButton>
              </>}
              <div id={"hubspotForm"} style={{
                padding: renderHubspotForm ? 20 : 0,
                width: "100%",
              }}>
                {
                  renderHubspotForm &&
                  <div id="loader" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress size={50} className={classes.buttonProgress} />
                  </div>
                }
              </div>
            </div> :
            <div className={classes.passwordFormWrapper}>
              <Typography className={cx(classes.typography, classes.margin, { [classes.typographyFlex]: true })}
                          align={"center"}>
                {"This is a limited-access page. Please enter your password to access it."}
              </Typography>
              <div className={cx(
                classes.fieldsContainer,
              )}
              >
                <div className={classes.label}>{"Enter password"}</div>
                <input type={"password"}
                       onKeyDown={handleEnter}
                       aria-required="true"
                       className={cx(classes.input, {
                         [classes.inputWithError]: userPasswordError,
                       })}
                       data-garden-focus-visible="true"
                       value={userPassword}
                       onChange={changeUserPassword}
                       placeholder={"Enter password"}
                />
                {
                  !!userPasswordError &&
                  <div className={classes.inputError}>
                    {"Password is not valid"}
                  </div>
                }
              </div>
              <CustomButton variant="contained" color="primary" mb={3}
                            onClick={checkAccess}
                            className={classes.submitButton}
              >
                {"Confirm"}
              </CustomButton>
            </div>
        }
      </PageWithCmsWrapper>
    </>
  );
};

export default LandingPagesTemplate;


export const query = graphql`
query LandingPagesTemplate($id: String!)
{
  strapiLandingPages(id
:
  {
    eq: $id
  }
)
  {
    id
    content
    created_at
    SeoTitle
    MetaDescriptions
    image
    formFields
    {
      fields
      {
        type
        required
        placeholder
        title
        options
        {
          id
          value
        }
        api_name
        value
      }
      hidden_fields
      {
        api_name
        value
      }
    }
    submitButtonText
    addPagePathToRequestBody
    requestUrl
    runIfRequestIsSuccessOPTION
    runIfRequestIsSuccessBODY
    password
  }
}
`;

const TextInput = (props) => {
    const classes = useStyles();
    const { isRequestSend, field, placeholder, errorsList, form, changeVal, title } = props;

    return <div className={cx(
      classes.fieldsContainerMini,
      {
        [classes.blurStyle]: isRequestSend,
      },
    )}
    >
      <div className={classes.label}>{title ?? placeholder}</div>
      <input type={field.type}
             name={field.api_name}
             aria-required="true"
             className={cx(classes.input, {
               [classes.inputWithError]: !!errorsList[field.api_name].length,
             })}
             data-garden-focus-visible="true"
             value={form[field.api_name]}
             onChange={changeVal}
             placeholder={placeholder}
             disabled={isRequestSend}
             required={field.required}
      />
      {
        !!errorsList[field.api_name].length &&
        <div className={classes.inputError}>
          {errorsList[field.api_name]}
        </div>
      }
    </div>;
  }
;

const PhoneInput = (props) => {
    const classes = useStyles();
    const { isRequestSend, field, placeholder, errorsList, form, changeVal, title } = props;

    return <div className={cx(
      classes.fieldsContainerMini,
      {
        [classes.blurStyle]: isRequestSend,
      },
    )}
    >
      <div className={classes.label}>{title ?? placeholder}</div>
      <InputMask
        mask="(999) 999-9999"
        value={form[field.api_name]}
        disabled={isRequestSend}
        maskChar=" "
        onChange={(e) => changeVal(e, "phone")}
      >
        {() =>
          <input name={field.api_name}
                 type="tel"
                 pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                 aria-required="true"
                 className={cx(classes.input, {
                   [classes.inputWithError]: !!errorsList[field.api_name].length,
                 })}
                 data-garden-focus-visible="true"
                 placeholder={placeholder}
          />}
      </InputMask>
      {
        !!errorsList[field.api_name].length &&
        <div className={classes.inputError}>
          {errorsList[field.api_name]}
        </div>
      }
    </div>;
  }
;

const Selector = (props) => {
  const classes = useStyles();
  const { isRequestSend, field, placeholder, errorsList, form, changeVal, title } = props;

  return <div className={cx(
    classes.fieldsContainerMini,
    {
      [classes.blurStyle]: isRequestSend,
    },
  )}>
    <div className={classes.label}>{title ?? placeholder}</div>
    <FormControl variant="outlined"
                 className={classes.formControl}
                 placeholder={placeholder}
                 error={!!errorsList[field.api_name].length}
    >
      <Select
        displayEmpty
        labelId="demo-simple-select-outlined-label"
        name={field.api_name}
        defaultValue={""}
        classes={{
          outlined: classes.formControlInputOutlined,
          input: classes.formControlInput,
          notchedOutline: !!errorsList[field.api_name].length ? classes.inputWithError : {},
        }}
        value={form[field.api_name]}
        onChange={changeVal}
        placeholder={placeholder}
        disabled={isRequestSend}
        IconComponent={SelectArrow}
      >
        <MenuItem value="" disabled>
          <em>{placeholder}</em>
        </MenuItem>
        {field.options.map(({ id, value }) => (
          <MenuItem value={id} key={id}>{value}</MenuItem>
        ))}
      </Select>
    </FormControl>
    {
      !!errorsList[field.api_name].length &&
      <div className={classes.inputError}>
        {errorsList[field.api_name]}
      </div>
    }
  </div>;
};
